import { LoaderOptions } from '@flipp/shopper-ad-web-shared';

const observeSPA = ({
  targetSelector,
  publisherNameIdentifier,
  siteId,
  zoneIds,
  options,
}: Readonly<{
  targetSelector: string;
  publisherNameIdentifier: string;
  siteId: number;
  zoneIds: readonly number[];
  options: LoaderOptions;
}>) => {
  // eslint-disable-next-line functional/no-let
  let oldTargetNode = document.querySelector(targetSelector);
  const observer = new MutationObserver(() => {
    const targetNode = document.querySelector(targetSelector);
    if (targetNode && targetNode !== oldTargetNode) {
      oldTargetNode = targetNode;
      const flippIframe = !!targetNode.querySelector(`.flippxp-frame`);
      if (!flippIframe) {
        observer.disconnect();
        window.flippxp.registerSlot(
          targetSelector,
          publisherNameIdentifier,
          siteId,
          zoneIds,
          options,
        );
      }
    }
  });
  const spaObserveTarget = options.spaObserveTargetSelector
    ? document.querySelector(options.spaObserveTargetSelector)
    : null;
  if (spaObserveTarget || document.body) {
    observer.observe(spaObserveTarget ?? document.body, {
      childList: true,
      subtree: true,
    });
  }
};

export { observeSPA };
