export default [
  'lettuce',
  'spinach',
  'broccoli',
  'carrot',
  'cauliflower',
  'peas',
  'tomato',
  'cucumber',
  'bell pepper',
  'onion',
  'garlic',
  'eggplant',
  'zucchini',
  'brussels sprouts',
  'kale',
  'mushrooms',
  'radish',
  'asparagus',
  'beets',
  'green beans',
  'celery',
  'cabbage',
  'leek',
  'turnip',
  'parsnip',
  'squash',
  'artichoke',
  'sweet potato',
  'swiss chard',
  'chili',
  'okra',
  'collard greens',
  'pumpkin',
  'avocado',
  'russet potatoes',
  'red onion',
  'white onion',
  'chicken breast',
  'chicken breasts',
  'chicken thigh',
  'chicken wing',
  'chicken drumstick',
  'chicken liver',
  'whole chicken',
  'ground beef',
  'beef steak',
  'beef brisket',
  'beef shank',
  'beef rib',
  'beef tenderloin',
  'beef chuck',
  'beef loin',
  'pork loin',
  'pork chop',
  'pork belly',
  'pork rib',
  'pork shoulder',
  'pork leg',
  'ground pork',
  'turkey breast',
  'ground turkey',
  'turkey leg',
  'turkey wing',
  'whole turkey',
  'duck breast',
  'duck leg',
  'whole duck',
  'chicken',
  'beef',
  'pork',
  'lamb',
  'turkey',
  'duck',
  'venison',
  'rabbit',
  'bacon',
  'ham',
  'bison',
  'goose',
  'liver',
  'quail',
  'pheasant',
  'corned beef',
  'ribs',
  'steak',
  'veal',
  'mutton',
  'lamb chop',
  'lamb leg',
  'lamb shank',
  'lamb rib',
  'rabbit loin',
  'venison steak',
  'bison steak',
  'milk',
  'cheese',
  'butter',
  'yogurt',
  'eggs',
  'whey',
  'curd',
  'cream cheese',
  'almond milk',
  'cheddar',
  'brie',
  'feta',
  'gouda',
  'mozzarella',
  'parmesan',
  'swiss cheese',
  'blue cheese',
  'cottage cheese',
  'sour cream',
  'whole milk',
  'skim milk',
  'semi-skimmed milk',
  '2% milk',
  'heavy cream',
  'whipping cream',
  'light cream',
  'sour cream',
  'double cream',
  'clotted cream',
  'unsalted butter',
  'salted butter',
  'cultured butter',
  'ghee',
  'fresh cheese',
  'aged cheese',
  'soft cheese',
  'hard cheese',
  'blue cheese',
  'cheddar cheese',
  'mozzarella cheese',
  'parmesan cheese',
  'feta cheese',
  'goat cheese',
  'ricotta cheese',
  'camembert cheese',
  'brie cheese',
  'cream cheese',
  'cottage cheese',
  'plain yogurt',
  'greek yogurt',
  'low-fat yogurt',
  'full-fat yogurt',
  'yogurt drink',
  'egg white',
  'egg yolk',
  'quail egg',
  'duck egg',
  'egg',
  'eggs',
  'popcorn',
  'whey protein',
  'casein',
  'lactose-free milk',
  'condensed milk',
  'evaporated milk',
  'milk powder',
  'salmon',
  'tuna',
  'shrimp',
  'lobster',
  'crab',
  'mussels',
  'oysters',
  'trout',
  'squid',
  'octopus',
  'cod',
  'halibut',
  'herring',
  'mackerel',
  'sardines',
  'sea bass',
  'tilapia',
  'scallops',
  'clams',
  'catfish',
  'wild salmon',
  'farmed salmon',
  'atlantic salmon',
  'pacific salmon',
  'smoked salmon',
  'salmon fillet',
  'salmon steak',
  'tuna steak',
  'tuna sashimi',
  'yellowfin tuna',
  'bluefin tuna',
  'albacore tuna',
  'canned tuna',
  'king shrimp',
  'tiger shrimp',
  'white shrimp',
  'pink shrimp',
  'dried shrimp',
  'shrimp paste',
  'blue crab',
  'king crab',
  'soft shell crab',
  'snow crab',
  'crab meat',
  'crab claw',
  'blue mussel',
  'green mussel',
  'pacific oyster',
  'rock oyster',
  'giant oyster',
  'rainbow trout',
  'brown trout',
  'sea trout',
  'squid ink',
  'squid tentacles',
  'baby octopus',
  'octopus tentacles',
  'cod fillet',
  'black cod',
  'cod liver',
  'halibut steak',
  'halibut fillet',
  'fresh herring',
  'pickled herring',
  'spanish mackerel',
  'king mackerel',
  'freshwater sardines',
  'sea sardines',
  'sea bass fillet',
  'tilapia fillet',
  'sea scallop',
  'bay scallop',
  'hard clam',
  'soft clam',
  'surf clam',
  'catfish fillet',
  'grilled catfish',
  'lobster tail',
  'lobster claw',
  'spiny lobster',
  'eel meat',
  'jellyfish',
  'sea urchin',
  'roe',
  'caviar',
  'apple',
  'apples',
  'banana',
  'cherry',
  'date',
  'grape',
  'lemon',
  'orange',
  'peach',
  'pear',
  'strawberry',
  'watermelon',
  'blueberry',
  'pineapple',
  'kiwi',
  'mango',
  'papaya',
  'plum',
  'blackberry',
  'raspberry',
  'tangerine',
  'lime',
  'grapefruit',
  'cranberry',
  'pomegranate',
  'lychee',
  'fig',
  'passion fruit',
  'guava',
  'apricot',
  'goji berry',
  'jackfruit',
  'rice',
  'wheat',
  'quinoa',
  'barley',
  'oats',
  'cornmeal',
  'rye',
  'almonds',
  'peanuts',
  'walnuts',
  'cashews',
  'pistachios',
  'pecans',
  'macadamia',
  'hazelnuts',
  'chestnuts',
  'pine nuts',
  'flaxseed',
  'sesame seeds',
];
