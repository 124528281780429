import { LoaderOptions, asNumber } from '@flipp/shopper-ad-web-shared';

export function overrideOptions(
  options: Readonly<LoaderOptions>,
): LoaderOptions {
  const url = new URL(window.location.toString());
  const dwellExpandable = url.searchParams.get('flipp-test-dwell-expandable');
  const experienceLimitRaw = url.searchParams.get(
    'flipp-test-experience-limit',
  );
  const experienceLimit = asNumber(experienceLimitRaw, 0);
  const startCompact = url.searchParams.get('flipp-test-start-compact');
  const nestedIframeResizing = url.searchParams.get(
    'flipp-test-nested-iframe-resizing',
  );
  const expandOnReadMore = url.searchParams.get(
    'flipp-test-expand-on-read-more',
  );
  const rootElement = url.searchParams.get('flipp-test-root-element');
  const nestedIframeShared = url.searchParams.get(
    'flipp-test-nested-iframe-shared',
  );
  const wideCompact = url.searchParams.get('flipp-test-wide-compact');
  const wideCompactBackgroundColor = url.searchParams.get(
    'flipp-test-wide-compact-background-color',
  );

  // eslint-disable-next-line functional/no-let
  let updatedOptions = { ...options };
  if (dwellExpandable !== null) {
    updatedOptions = {
      ...updatedOptions,
      dwellExpandable: dwellExpandable === 'true',
    };
  }
  if (experienceLimit) {
    updatedOptions = {
      ...updatedOptions,
      experienceLimit: Math.max(1, experienceLimit),
    };
  }
  if (startCompact !== null) {
    updatedOptions = {
      ...updatedOptions,
      startCompact: startCompact === 'true',
    };
  }
  if (nestedIframeResizing !== null) {
    updatedOptions = {
      ...updatedOptions,
      nestedIframeResizing: nestedIframeResizing === 'true',
    };
  }
  if (expandOnReadMore !== null) {
    updatedOptions = {
      ...updatedOptions,
      expandOnReadMore: expandOnReadMore === 'true',
    };
  }
  if (rootElement !== null) {
    updatedOptions = {
      ...updatedOptions,
      rootElement,
    };
  }
  if (nestedIframeShared !== null) {
    updatedOptions = {
      ...updatedOptions,
      nestedIframeShared: nestedIframeShared === 'true',
    };
  }
  if (wideCompact !== null && wideCompactBackgroundColor !== null) {
    updatedOptions = {
      ...updatedOptions,
      wideCompact: wideCompact === 'true',
      wideCompactBackgroundColor,
    };
  }

  return updatedOptions;
}
