/* eslint-disable functional/no-let */
export interface Geom {
  top: number;
  height: number;
  screenHeight: number;
}

export const calculateGeomData = (
  parentElement: Readonly<Element>,
  contentElement: Readonly<HTMLElement>,
  parentWindow: Readonly<Window>,
): Geom | null => {
  const crect = contentElement.getBoundingClientRect();
  const rect = {
    top: crect.top,
    height: crect.height,
    bottom: crect.bottom,
  };

  if (parentWindow !== contentElement.ownerDocument.defaultView) {
    const brect = parentElement.getBoundingClientRect();

    rect.top = brect.top + crect.top;
    rect.height = Math.min(rect.height, brect.height);
    rect.bottom = rect.top + rect.height;
  }

  if (rect.height === 0) {
    return null;
  }

  let vh = rect.height;
  let vt = 0;

  if (rect.top > parentWindow.innerHeight || rect.bottom <= 0) {
    return {
      top: 0,
      height: 0,
      screenHeight: parentWindow.innerHeight,
    };
  }

  // Top of ad is above top of viewport (Case 3 and 4)
  if (rect.top < 0) {
    vt = -rect.top;
    vh = vh - vt;
  }

  // Bottom of ad is above bottom of viewport (Case 2,3,4)
  if (rect.bottom > parentWindow.innerHeight) {
    vh = vh - (rect.bottom - parentWindow.innerHeight);
  }

  vh = Math.max(vh, 0);

  return {
    top: vt,
    height: vh,
    screenHeight: parentWindow.innerHeight,
  };
};
