export function thisIframeInParent() {
  // eslint-disable-next-line functional/no-let
  let parentDocument;
  try {
    parentDocument = window.parent.document;
  } catch {
    console.warn('Will not resize or position if on another domain.');
    return null;
  }

  if (!parentDocument) {
    return null;
  }

  const parentFrames = parentDocument.querySelectorAll('iframe');
  for (let i = 0; i < parentFrames.length; i++) {
    const frame = parentFrames[i];
    try {
      if (window === frame.contentWindow) {
        return frame;
      }
    } catch {
      continue;
    }
  }

  return null;
}

// Resize own iframe (must be on shared domain with parent)
export function resizeOwnIframe(
  size: number,
  nestedIframeShared: boolean,
  fullBleed: boolean,
) {
  // Check we're in the right iframe that _only_ contains our content
  // This check may be too agressive, but start of on the side of safety
  if (
    ((document.body && document.body.children.length !== 1) ||
      document.body.children[0].id.substring(0, 6) !== 'flipp-') &&
    !(isInGoogleAdsIframe() || nestedIframeShared)
  ) {
    console.warn('Will not resize if not only our container present.');
    return;
  }

  const frame = thisIframeInParent();
  if (!frame) {
    return;
  }

  const currentHeight = document.body.offsetHeight | 0;
  const flippHeight =
    document.querySelector('.flippxp-frame')?.getBoundingClientRect().height ||
    0;
  const nonFlippHeight = Math.max(0, currentHeight - flippHeight);

  const totalHeight = size + nonFlippHeight;
  frame.style.height = `${totalHeight}px`;

  if (fullBleed) {
    frame.style.width = '100%';
  }
}

export function isInGoogleAdsIframe(): boolean {
  return (
    !!window.frameElement?.id.startsWith('google_ads_iframe') &&
    document.querySelectorAll(
      '.GoogleActiveViewElement, .GoogleActiveViewInnerContainer',
    ).length > 0
  );
}
