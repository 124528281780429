import Slot from './Slot';
import { LoaderOptions } from '@flipp/shopper-ad-web-shared';

// eslint-disable-next-line functional/no-classes
export class SlotManager {
  private readonly slots: Slot[];

  private waitingForDocument: boolean;

  constructor() {
    this.waitingForDocument = false;
    this.slots = [];
  }

  checkReady(): boolean {
    const foundEls = this.slotsAllInDocument();

    if (
      foundEls &&
      (document.readyState === 'interactive' ||
        document.readyState === 'complete')
    ) {
      return true;
    }

    const foundEl = this.anySlotInDocument();
    if (foundEl && document.readyState === 'complete') {
      return true;
    }

    if (!foundEls) {
      console.info('Missing slot target div at state', document.readyState);
    }

    return false;
  }

  handleReadyStateChange = () => {
    if (this.checkReady()) {
      this.waitingForDocument = false;
      this.load();
      document.removeEventListener(
        'readystatechange',
        this.handleReadyStateChange,
      );
    }
    return null;
  };

  anySlotInDocument(): boolean {
    return this.slots.some(slot => document.querySelector(slot.targetSelector));
  }

  slotsAllInDocument(): boolean {
    return this.slots.every(slot =>
      Boolean(document.querySelector(slot.targetSelector)),
    );
  }

  load() {
    this.slots.forEach(slot => slot.request());
    return null;
  }

  register(
    targetSelector: string,
    publisherNameIdentifier: string,
    siteId: number,
    zoneIds: readonly number[],
    options: LoaderOptions = {},
  ) {
    const settings = window.flippxp.settings || {};
    const slot = new Slot(
      targetSelector,
      publisherNameIdentifier,
      siteId,
      zoneIds,
      { ...settings, ...options },
    );

    this.slots.push(slot);

    if (this.checkReady()) {
      this.load();
    } else if (!this.waitingForDocument) {
      this.waitingForDocument = true;
      document.addEventListener(
        'readystatechange',
        this.handleReadyStateChange,
      );
    }

    return slot;
  }
}

export default SlotManager;
