/* eslint-disable functional/no-let */
type ThrottleOpts = {
  leading?: boolean;
  trailing?: boolean;
};

/** Trivial throttle function
 * NOTE: Defaults are to fire on leading but not trailing
 * Doesn't support this tracking, etc.
 * @param fn - The function to throttle
 * @param delay - The delay in ms to throttle the function
 * @param opts - Options for the throttle
 */
export const throttle = function <T extends unknown[]>(
  cb: (...args: T) => null,
  delay: number,
  { leading = true, trailing = false }: ThrottleOpts = {},
) {
  /** Are we currently waiting for the delay to expire? */
  let ticking = false;
  /** Have calls been made since last execution occured? */
  let needsCall = false;
  // This casting isn't pretty, but it wasn't clear how to do it otherwise
  let lastArgs = [] as unknown as T;

  // eslint-disable-next-line functional/functional-parameters
  return function (...args: T) {
    needsCall = true;
    lastArgs = args;

    if (!ticking) {
      ticking = true;

      if (leading) {
        needsCall = false;
        cb(...lastArgs);
      }

      setTimeout(() => {
        if (trailing && needsCall) {
          cb(...lastArgs);
        }
        ticking = false;
        needsCall = false;
      }, delay);
    }
  };
};
