import { calculateGeomData } from './geom';
import { overrideOptions } from './options';
import SlotManager from './SlotManager';
import { observeSPA } from './singlePageApp';

import {
  AnalyticsQueue,
  LoaderOptions,
  PostMessageEvent,
  createAppInitializedBeacon,
  handleMessageFromNative,
  isBot,
  logUnknownError,
  Tags,
  Logger,
} from '@flipp/shopper-ad-web-shared';

try {
  // eslint-disable-next-line functional/no-let
  let firedInit = false;
  // Handle incoming native SDK messages
  window.handleMessageFromNative = handleMessageFromNative;

  // Publisher Tag Interface
  window.flippxp = window.flippxp || {};
  window.flippxp.evTag = window.flippxp.evTag || {};
  window.flippxp.run = window.flippxp.run || [];
  window.flippxp.debugMode = window.flippxp.debugMode || {};
  window.flippxp.calculateGeomData = calculateGeomData;
  window.flippxp.overrideOptions = overrideOptions;
  window.flippxp.vimpTrackerFunc =
    window.flippxp.vimpTrackerFunc ||
    (() => {
      if (window !== window.parent) {
        window.parent.postMessage({ type: PostMessageEvent.VIMP }, '*');
      }
    });
  window.flippxp.cookieSynced = window.flippxp.cookieSynced || false;
  window.flippxp.registerSlot = function (
    targetSelector: string,
    publisherNameIdentifier: string,
    siteId: number,
    zoneIds: readonly number[],
    options: LoaderOptions = {},
  ) {
    // If we haven't fired the init event yet then do it now
    if (!firedInit) {
      AnalyticsQueue.prodAnalytics = !!window.flippxp.debugMode?.prodAnalytics;
      firedInit = true;
      AnalyticsQueue.enqueue(
        createAppInitializedBeacon({
          partnerNameIdentifier: publisherNameIdentifier,
          siteId,
          zoneId: zoneIds[0] || 0,
          options,
          postalCode: window.flippxp.geo?.postal_code,
          country: window.flippxp.geo?.country,
        }),
      );
      Logger.debug('Analytics sendAppInitialized');
    }
    Tags.reset();
    if (siteId === 1179443) {
      const el = document.getElementById('flyerWidgetLabel');
      if (el) {
        el.style.display = 'none';
      }
    }

    if (options.singlePageApp ?? window.flippxp.settings?.singlePageApp) {
      observeSPA({
        targetSelector,
        publisherNameIdentifier,
        siteId,
        zoneIds,
        options,
      });
    }

    window.flippxp.slotManager =
      window.flippxp.slotManager || new SlotManager();
    return window.flippxp.slotManager.register(
      targetSelector,
      publisherNameIdentifier,
      siteId,
      zoneIds || [],
      window.flippxp.overrideOptions(options),
    );
  };

  // Execute command queue.
  while (window.flippxp.run.length > 0) {
    const callback = window.flippxp.run.shift();
    if (!isBot() && typeof callback === 'function') {
      callback();
    }
  }

  // Patch queue to execute on insertion.
  window.flippxp.run.push = function (callback: () => null) {
    if (!isBot() && typeof callback === 'function') {
      callback();
    }
    return null;
  };
} catch (e) {
  logUnknownError(e);
}
