// Bad Words List - ROT13 Encoded
// Autogenerated from prep_badwords.js. Do not edit directly.
export const badKeywords = [
  '4puna',
  '8puna',
  'nobegvba',
  'npg bs rivy',
  'npg bs frqvgvba',
  'npgvir fubbgre',
  'npgvir fubbgref',
  'nqnz gbyrqb',
  'nqnz lnuvlr tnqnua',
  'nqbys uvgyre',
  'nqhyg pnz',
  'nqhyg pnzf',
  'nqhyg svyz',
  'nqhyg ivqrbf',
  'nqhyg jropnz',
  'nqhyg jrofvgrf',
  'nuzrq tunvynav',
  'nuzrq tbqnar',
  'nuzrq voenuvz ny zhtunffvy',
  'nuzrq zbunzzrq unzrq nyv',
  'nubyr',
  'nve fgevxr',
  'nx 47',
  'nx-15',
  'nx-47',
  'nx47',
  'nxeba',
  'ny dnrqn',
  'ny dhrqn',
  'ny funonno',
  'ny funz',
  'ny hzne zhwnuvqrra',
  'ny-snqy',
  'ny-uneo',
  'ny-xubone',
  'ny-zvqune',
  'ny-ahfen sebag',
  'ny-dnrqn',
  'ny-funonno',
  'ny-mnjnuvev',
  'nyntnfpb',
  'nypbubyvfz',
  'nyrccb',
  'nyrk wbarf',
  'nyv noh zhxunzznq',
  'nyv ngjn',
  'nyv fnrq ova nyv ry ubbevr',
  'nyvrkcerff',
  'nyy yvirf znggre',
  'nyynu',
  'nyyrtngvbaf',
  'nyygry',
  'nyz',
  'nydnrqn',
  'nydhena',
  'nyg evtug',
  'nzore arny',
  'nzzba ohaql',
  'nany',
  'nany ornqf',
  'nany frk',
  'narzvn',
  'natre znantrzrag',
  'navzny pehrygl',
  'navzny frk',
  'naberkvn',
  'naberkvp',
  'nagv vfynz',
  'nagv-inp',
  'nagv-inppvar',
  'nagv-ink',
  'nagvsn',
  'nahf',
  'najne ny njynxv',
  'najne ny-njynxv',
  'ne-15 evsyr',
  'nesvq',
  'nezrq fgnaqbss',
  'nfze rngf',
  'nfcretre’f',
  'nfcretref',
  'nffnffvangvba',
  'nffnhyg',
  'nffnhyg evsyr',
  'nffnhyg evsyr ona',
  'ngynagn znffnper',
  'ngbzvp obzo',
  'nggnpx',
  'nggnpx va ybjre znaunggna',
  'nggnpx ba cnevf',
  'nhfpujvgm',
  'nhgb nppvqrag',
  'njynxv',
  'nlzna ny-mnjnuvev',
  'o12',
  'onpgrevn',
  'onq obff',
  'onqnff',
  'ontuqnq',
  'onyyvfgvp zvffvyrf',
  'oneronpx',
  'oneryl yrtny',
  'onfune ny-nffnq',
  'onfuve nffnq',
  'onggyrsvryq',
  'ornfgvnyvgl',
  'orurnqvat',
  'ora pehzc',
  'orfgvnyvgl',
  'ovyny unqsv',
  'ova ynqra',
  'ovatr rngvat',
  'obqlpnz',
  'obzo',
  'obzovat',
  'obbfgre',
  'oehgnyvgl',
  'ohxxnxr',
  'ohyvzvn',
  'ohetynel',
  'pnepvabtra',
  'pyhfgreshpx',
  'pbpxfhpxre',
  'pbagnzvangrq',
  'pbeban',
  'pbeban ivehf',
  'pbebanivehf',
  'pbivq',
  'pbivq 19',
  'pbivq-19',
  'pebff-pbagnzvangvba',
  'phesrj',
  'qnvyl fgbezre',
  'qrshaq gur cbyvpr',
  'r.pbyv',
  'snttvg',
  'snttbg',
  'svernez',
  'sybbq',
  'sbbq nqqvpgvbaf',
  'sernx',
  'sernx bhg',
  'tnmn',
  'tha',
  'tha evtugf',
  'unznf',
  'unenffzrag',
  'ubybpnhfg',
  'ubeevsvp nggnpx',
  "v pna'g oerngur",
  'vafheerpgvba',
  'vfenry',
  'xvyy',
  'xvyyrq',
  'xvyyf',
  'xxx',
  'xbeban',
  'xh xyhk xyna',
  'ynjfhvg',
  'ynlbssf',
  'yvfgrevn',
  'yvirfrk',
  'ybpxqbja',
  'ybbgvat',
  'znavsrfgnag',
  'znavsrfgngvba',
  'znavsrfgngvbaf',
  'znff fubbgvat',
  'zvffvyr',
  'zbyrfg',
  'zbyrfgrq',
  'zhxonat',
  'zheqre',
  'zheqrere',
  'zhgvyngvba',
  'anxrq',
  'anmv',
  'arb anmv',
  'avtnobb',
  'avttn',
  'avttre',
  'avttref',
  'avgtyrg',
  'aen',
  'ahqr',
  'bofprar',
  'bvy fcvyy',
  'bhgoernx',
  'cnyrfgvavna',
  'cnaqrzvp',
  'crqbcuvyr',
  'crrq',
  'crccre fcenl',
  'crfgvpvqr',
  'cvpn qvfbeqre',
  'cbvfbavat',
  'cbyyhgvba',
  'cbea',
  'cerpvapg ohearq',
  'cebhq oblf',
  'ertvf xbepuvafxv-cndhrg',
  'evsyr',
  'evbg',
  'evbgre',
  'evbgf',
  'evc',
  'ehoore ohyyrgf',
  'fnyzbaryyn',
  'fnaqavttre',
  'fngna',
  'fryravhz',
  'frk',
  'frkhny zvfpbaqhpg',
  'fuvg',
  'fvpxrarq',
  'fxvaurnq',
  'fghqragf xvyyrq',
  'fhrq',
  'fhvpvqny',
  'fhvpvqr',
  'fhcerznpvfg',
  'fhcerzr pbheg',
  'grne tnf',
  'grnetnf',
  'greebevfz',
  'gursg',
  'gehzc',
  'gehzc ergnyvngvba',
  'gjvggre gehzc ivbyrapr',
  'ha inkkrq',
  'ha-inppvangrq',
  'haerfg fubbgvat',
  'hcevfvat',
  'inppvar znaqngr',
  'ink znaqngr',
  'ivehf',
  'jrncbaf',
  'juvgr fhcerznpvfg',
];
