import { fireOnFirstInView } from './viewportUtils';
import { getContextCategory, getContextKeywords } from './context';
import { isInGoogleAdsIframe, resizeOwnIframe } from './iframeHelpers';
import { checkPageForKeywords } from './KeywordsManager';
import { badKeywords } from './bad-keywords';

import {
  AnalyticsQueue,
  asNumber,
  createDecisionRequestContextBeacon,
  createDecisionRequestMadeBeacon,
  createDecisionResponseFailureBeacon,
  createDecisionResponseSuccessBeacon,
  createSlotImpressionExperienceBeacon,
  deferSDKLazyLoad,
  ExperienceViewportDetail,
  getCountry,
  getPostalCode,
  getSDKKeywords,
  getUserKey,
  isIntersectionObserverSupported,
  KevelCustomData,
  KevelPlacement,
  KevelRequest,
  LoaderOptions,
  logError,
  Logger,
  logUnknownError,
  sendSDKLoadError,
  Tags,
  mraidExpandLoad,
} from '@flipp/shopper-ad-web-shared';
import { CampaignResponseBody } from '@flipp/shopper-ad-web-schemas';
import Experience from './Experience';
import {
  setStyleForAdContainers,
  setStyleForIframeContainers,
  setStyleForRootElements,
} from './GAMHelpers';

const NETWORK_ID = __kevelNetwork;
const AD_TYPES = [4309, 641];
const TARGET_NAME = 'inline';
const DEFAULT_EXPERIENCE_COUNT = 1;
const DEFAULT_DWELL_TIMER_MS = 3000;
const COUNTDOWN_STEPS = 3;
const AUTO_EXPAND_LIMIT = 1;

// eslint-disable-next-line functional/no-classes
export class Slot {
  private decisionPayload?: CampaignResponseBody;
  private readonly experiences: Experience[];
  private readonly markers: {
    el: HTMLDivElement;
    active: boolean;
    index: number;
  }[];
  private requested: boolean;

  private experienceLimit: number;

  readonly targetSelector: string;
  private targetEl?: HTMLElement | null;
  private readonly publisherNameIdentifier: string;
  private readonly siteId: number;
  private readonly zoneIds: number[];
  private readonly options: LoaderOptions;
  private dwellObserver: IntersectionObserver | null;
  private readonly scrollBeyondObserver: IntersectionObserver | null;
  private readonly hoverObserver: IntersectionObserver | null;
  private readonly isNativeMobileApp?: boolean;
  private fullyVisibleExperiences: number[];

  private countdownExperience?: number;
  private countdownStart?: number;
  private countdownInterval?: number;

  private readonly startCountdownFunctions = new Map<number, () => null>();

  private readonly slotFillResultCallback?: (filled: boolean) => void;

  constructor(
    targetSelector: string,
    publisherNameIdentifier: string,
    siteId: number,
    zoneIds: readonly number[],
    options: Readonly<LoaderOptions>,
  ) {
    this.targetSelector = targetSelector;
    this.publisherNameIdentifier = publisherNameIdentifier
      ? publisherNameIdentifier.toString()
      : 'undefined';
    this.siteId = asNumber(siteId);
    this.zoneIds = zoneIds.map(id => asNumber(id));
    this.options = options;
    this.requested = false;
    this.experiences = [];
    this.markers = [];
    this.isNativeMobileApp =
      (!!options.sdkConfig || options.isNativeGAMWebview) ?? false;
    const url = new URL(window.location.toString());
    this.experienceLimit =
      Number(url.searchParams.get('flipp-experience-limit')) ||
      options.experienceLimit ||
      DEFAULT_EXPERIENCE_COUNT;
    this.fullyVisibleExperiences = [];

    if (
      this.options.isMRAID ||
      this.isNativeMobileApp ||
      !isIntersectionObserverSupported()
    ) {
      this.dwellObserver = null;
      this.scrollBeyondObserver = null;
      this.hoverObserver = null;
    } else {
      this.dwellObserver = new IntersectionObserver(this.observerCallback, {
        rootMargin: '-70% 0px -5% 0px',
      });

      this.scrollBeyondObserver = new IntersectionObserver(
        this.scrollBeyondCallback,
        {
          rootMargin: '-95% 0px 0px 0px',
        },
      );
      this.hoverObserver = new IntersectionObserver(this.hoverCallback, {
        threshold: 1.0,
      });
    }
    this.slotFillResultCallback =
      typeof options.slotFillResultCallback === 'function'
        ? options.slotFillResultCallback
        : undefined;
  }

  readonly startCountdownWithMap = (i: number): (() => null) => {
    if (!this.startCountdownFunctions.has(i)) {
      this.startCountdownFunctions.set(i, () => this.startCountdown(i));
    }
    return this.startCountdownFunctions.get(i) ?? (() => null);
  };

  private callSlotFillResultCallback(filled: boolean): void {
    if (this.options.slotFillResultCallback) {
      this.options.slotFillResultCallback(filled);
    }
  }

  tickCountdown = () => {
    if (
      this.countdownStart === undefined ||
      this.countdownExperience === undefined
    ) {
      return null;
    }

    const elapsed = Date.now() - this.countdownStart;
    const totalDuration = this.getDwellDuration(this.countdownExperience);

    const experience = this.experiences[this.countdownExperience];
    const interval =
      totalDuration / this.getDwellSteps(this.countdownExperience) + 0.01;
    experience.sendCountdownState(
      this.countdownStart + totalDuration,
      interval,
    );

    if (elapsed >= totalDuration) {
      this.completeCountdown();
    }
  };

  completeCountdown() {
    if (this.countdownExperience === undefined) {
      return null;
    }

    const experience = this.experiences[this.countdownExperience];

    if (experience.preview) {
      experience.handleEndPreview();
      this.appendPreview();
    } else {
      experience.requestExpand();
    }

    this.stopCountdown();
  }

  stopCountdown() {
    if (this.countdownInterval) {
      window.clearInterval(this.countdownInterval);
    }

    if (this.countdownExperience !== undefined) {
      const experience = this.experiences[this.countdownExperience];
      experience.sendCountdownState();
    }

    this.countdownInterval = undefined;
    this.countdownExperience = undefined;
    this.countdownStart = undefined;

    return null;
  }

  startCountdown(experience: number) {
    const interval =
      this.getDwellDuration(experience) / this.getDwellSteps(experience);

    this.countdownExperience = experience;
    this.countdownStart = Date.now();

    this.tickCountdown();

    this.countdownInterval = window.setInterval(this.tickCountdown, interval);

    return null;
  }

  getDwellDuration(experience: number): number {
    const customData = this.decisionPayload?.decisions.inline[experience]
      .contents[0].data.customData as KevelCustomData;
    if (customData.dwellTimerMs) {
      return asNumber(
        customData.dwellTimerMs || DEFAULT_DWELL_TIMER_MS,
        DEFAULT_DWELL_TIMER_MS,
      );
    }
    return DEFAULT_DWELL_TIMER_MS;
  }

  getDwellSteps(experience: number): number {
    const customData = this.decisionPayload?.decisions.inline[experience]
      .contents[0].data.customData as KevelCustomData;
    if (customData.dwellSteps) {
      return asNumber(customData.dwellSteps, COUNTDOWN_STEPS);
    }
    return COUNTDOWN_STEPS;
  }

  hoverCallback = (entries: readonly IntersectionObserverEntry[]) => {
    entries.forEach((entry, i) => {
      const target = entry.target as HTMLElement;
      if (entry.isIntersecting) {
        this.fullyVisibleExperiences.push(i);
        target.addEventListener('mouseover', this.startCountdownWithMap(i));
      } else {
        this.fullyVisibleExperiences = this.fullyVisibleExperiences.filter(
          exp => i !== exp,
        );
        if (!this.markers[i].active) {
          target.removeEventListener(
            'mouseover',
            this.startCountdownWithMap(i),
          );
        }
      }
    });
    return null;
  };

  observerCallback = (entries: readonly IntersectionObserverEntry[]) => {
    entries.forEach(entry => {
      const target = entry.target as HTMLElement;
      this.markers.forEach(marker => {
        if (marker.el === target) {
          marker.active = entry.isIntersecting;
        }
      });
    });

    // eslint-disable-next-line functional/no-let
    let setActive = false;
    this.markers.forEach((marker, i) => {
      const experience = this.experiences[i];
      const lastExperience = i > 0 ? this.experiences[i - 1] : null;
      const isFirstExperience = i === 0;
      const lastExperienceExpanded =
        lastExperience && lastExperience.expandCount >= AUTO_EXPAND_LIMIT;
      const canBeActive =
        (!experience.preview && experience.expandCount < AUTO_EXPAND_LIMIT) ||
        (experience.preview && (lastExperienceExpanded || isFirstExperience));

      if (canBeActive && !setActive && marker.active) {
        if (this.countdownExperience !== i) {
          this.stopCountdown();
          if (experience.expandCount < AUTO_EXPAND_LIMIT) {
            if (
              experience.hoverExpandable &&
              experience.contentElement &&
              this.experiences.length === 1
            ) {
              experience.contentElement.addEventListener(
                'mouseover',
                this.startCountdownWithMap(i),
              );
            } else {
              this.startCountdown(i);
            }
          }
        }

        setActive = true;
      } else if (!marker.active) {
        if (!this.fullyVisibleExperiences.includes(i))
          experience.contentElement?.removeEventListener(
            'mouseover',
            this.startCountdownWithMap(i),
          );
        if (this.countdownExperience === i) this.stopCountdown();
      }
    });
  };

  private readonly nativeAppDwellExpandCallback = (
    viewportDetails: Readonly<ExperienceViewportDetail>,
  ) => {
    if (!this.options.dwellExpandable || this.experiences.length === 0) {
      return null;
    }
    const experience = this.experiences[0];

    const isExperienceInView =
      viewportDetails.top + 1 + viewportDetails.height >=
        experience.getHeight() &&
      viewportDetails.height >= viewportDetails.screenHeight / 2;

    if (experience.expandCount < AUTO_EXPAND_LIMIT && isExperienceInView) {
      if (this.countdownExperience !== 0) {
        this.stopCountdown();
        if (experience.expandCount < AUTO_EXPAND_LIMIT) {
          if (
            experience.hoverExpandable &&
            experience.contentElement &&
            this.experiences.length === 1
          ) {
            experience.contentElement.addEventListener(
              'mouseover',
              this.startCountdownWithMap(0),
            );
          } else {
            this.startCountdown(0);
          }
        }
      }
    } else if (!isExperienceInView) {
      if (!this.fullyVisibleExperiences.includes(0))
        experience.contentElement?.removeEventListener(
          'mouseover',
          this.startCountdownWithMap(0),
        );
      if (this.countdownExperience === 0) this.stopCountdown();
    }
    return null;
  };

  private readonly scrollBeyondCallback: IntersectionObserverCallback =
    entries => {
      entries
        // Ignore non-intersecting elements
        .filter(entry => entry.isIntersecting)
        // Find the matching Experience for each entry
        .map(entry => this.markers.find(marker => marker.el === entry.target))
        // Ignore if we can't find the experience
        .filter(exp => exp && this.experiences[exp.index])
        // Trigger the scroll beyond handler on each matched experience
        .forEach(exp => this.experiences[exp?.index ?? 0].scrollBeyond());

      return null;
    };

  request() {
    if (this.requested) {
      return null;
    }

    this.targetEl = document.querySelector(this.targetSelector);
    if (!this.targetEl) {
      console.log('No target div found for', this.targetSelector);
      return null;
    }
    if (this.isNativeMobileApp) {
      deferSDKLazyLoad(
        () => this.getExperiences(),
        this.nativeAppDwellExpandCallback,
      );
    } else if (this.options.isGoogleAmp || this.options.disableLazyLoading) {
      this.getExperiences();
    } else {
      fireOnFirstInView(
        this.targetEl,
        () => this.getExperiences(),
        this.options.rootElement,
      );
      if (this.options.isMRAID) {
        mraidExpandLoad(this.nativeAppDwellExpandCallback);
      }
    }

    this.requested = true;
  }

  private readonly resizeExperience = (
    experience: Readonly<Experience>,
    height: number,
  ) => {
    if (
      this.options.nestedIframe &&
      this.targetEl &&
      experience.contentElement
    ) {
      experience.contentElement.style.height = `${height}px`;
      resizeOwnIframe(
        this.targetEl.offsetHeight,
        !!this.options.nestedIframeShared,
        !!this.options.nestedIframeFullBleed,
      );
    }

    return null;
  };

  private insertPassback(passbackTag: string) {
    if (!this.targetEl) {
      return null;
    }

    const iframe = document.createElement('iframe');
    iframe.src = 'about:blank';

    iframe.style.width = this.options.passbackWidth || '100%';
    iframe.style.height = this.options.passbackHeight || '600px';
    iframe.setAttribute('scrolling', 'no');
    iframe.setAttribute('frameborder', 'no');

    this.targetEl.appendChild(iframe);

    if (!iframe.contentWindow) {
      return;
    }
    iframe.contentWindow.document.open();
    iframe.contentWindow.document.write(passbackTag);
    iframe.contentWindow.document.close();
  }

  handlePreviewEnded = () => {
    this.appendPreview();
    return null;
  };

  private addExperience(preview = false, experienceIdx = 0) {
    const decisionsLength =
      this.decisionPayload?.decisions?.inline?.length || 0;
    if (
      !this.targetEl ||
      !this.decisionPayload ||
      experienceIdx >= decisionsLength
    ) {
      if (this.options.sdkConfig) {
        sendSDKLoadError(this.options.sdkConfig.platform);
      }

      if (!preview && experienceIdx === 0) {
        this.callSlotFillResultCallback(false);
      }

      return null;
    }
    const optionsWithPreview = Object.assign({}, this.options, { preview });
    const experience = new Experience({
      targetEl: this.targetEl,
      publisherNameIdentifier: this.publisherNameIdentifier,
      data: this.decisionPayload?.decisions.inline[experienceIdx],
      options: optionsWithPreview,
      resizeCallback: this.resizeExperience,
      siteId: this.siteId,
      zoneIds: this.zoneIds,
      handlePreviewEnded: this.handlePreviewEnded,
      geoData: this.decisionPayload.location,
      keywords: [...getContextKeywords()],
      positionIndex:
        this.experienceLimit > 1 && decisionsLength > 1
          ? experienceIdx + 1
          : null,
    });
    this.experiences.push(experience);
    experience.render();
    if (experience.hoverExpandable && decisionsLength === 1) {
      experience.contentElement?.addEventListener('mouseleave', () => {
        this.stopCountdown();
      });
    }
    // Create marker that detects when bottom of experience is in view.
    const marker = document.createElement('div');
    marker.style.height = '0px';
    marker.classList.add('santinel');
    this.targetEl.appendChild(marker);
    this.markers.push({ el: marker, active: false, index: experienceIdx });
    if (this.scrollBeyondObserver && this.dwellObserver && this.hoverObserver) {
      this.scrollBeyondObserver.observe(marker);
      if (this.options.dwellExpandable) {
        if (
          experience.contentElement &&
          experience.hoverExpandable &&
          decisionsLength === 1
        ) {
          // Enable hover expandable experiences at bottom half of the viewport
          this.dwellObserver = new IntersectionObserver(this.observerCallback, {
            rootMargin: '-50% 0px 0px 0px',
          });
          this.hoverObserver.observe(experience.contentElement);
        }
        this.dwellObserver.observe(marker);
      }
    }
    if (!preview && experienceIdx === 0) {
      this.callSlotFillResultCallback(true);
    }
  }

  private appendPreview() {
    const decisionsLength = this.decisionPayload?.decisions?.inline.length || 0;
    if (!this.decisionPayload) {
      return null;
    }
    if (this.experienceLimit <= this.experiences.length) {
      return null;
    }

    if (decisionsLength <= this.experiences.length) {
      return null;
    }

    this.addExperience(true, this.experiences.length);
  }

  private setGAMStyle(decisionExist: boolean) {
    const minHeight = this.options.startCompact ? '610px' : 'initial';
    const style = `min-height: ${minHeight}; margin: 0 auto !important; height: auto !important; width: 100% !important; 
            display: block !important; max-height: initial !important`;
    const parentElement = window.parent.document;
    setStyleForAdContainers(parentElement, style);
    const rootElement = this.options.rootElement;
    if (!rootElement) {
      const gamContainerWidth = Math.min(
        this.options.gamContainerWidth || -1,
        parentElement.body.clientWidth,
      );
      setStyleForIframeContainers(
        style,
        decisionExist,
        args => this.callSlotFillResultCallback(args),
        !!this.options.nestedIframeResizing,
        gamContainerWidth,
      );
    } else {
      setStyleForRootElements(parentElement, style, rootElement);
    }
  }

  private getGeolocatedExperience(body: Readonly<KevelRequest>) {
    this.queueRequestMadeBeacon();
    const campaignsResponse = this.options.campaigns
      ? Promise.resolve(JSON.parse(this.options.campaigns))
      : fetch('__campaignsURL', {
          method: 'POST',
          credentials: 'omit',
          body: JSON.stringify(body),
          headers: {
            'Content-Type': 'text/plain',
          },
        }).then(response => {
          if (!response.ok) {
            this.callSlotFillResultCallback(false);
            if (this.options.sdkConfig) {
              sendSDKLoadError(this.options.sdkConfig.platform);
            }
            this.queueFailureBeacon(response);
          }
          return response.json();
        });

    campaignsResponse
      .then((data: Readonly<CampaignResponseBody>) => {
        this.queueSuccessBeacon(data);
        this.decisionPayload = data as CampaignResponseBody;
        const decisionExist =
          data.decisions &&
          typeof data.decisions === 'object' &&
          Object.keys(data.decisions).some(
            key =>
              key in data.decisions &&
              data.decisions[key as unknown as keyof typeof data.decisions], // The type is ugly, but it should be safe
          );

        if (isInGoogleAdsIframe()) {
          try {
            this.setGAMStyle(decisionExist);
          } catch (e) {
            if (e instanceof Error) {
              void logError(e.message, e.stack, window.location.href);
            } else {
              void logUnknownError(e);
            }
          }
        }

        // Specifically for Postmedia we need to hide the header if no decision was returned from the API
        const header =
          this.siteId === 1179443 &&
          document.getElementById('flyerWidgetLabel');
        if (decisionExist && header) {
          // eslint-disable-next-line functional/immutable-data
          header.style.display = 'block';
        }

        // If we have no decision and a passback, call the passback
        if (
          this.options.passbackTag &&
          (!data.decisions ||
            !data.decisions.inline ||
            data.decisions.inline.length < 1 ||
            data.decisions.inline[0] === null)
        ) {
          this.callSlotFillResultCallback(false);
          const tag =
            typeof this.options.passbackTag === 'function'
              ? this.options.passbackTag()
              : this.options.passbackTag;

          if (typeof tag === 'object' && typeof tag.then === 'function') {
            // NOTE: We return here to ensure that exceptions bubble up
            return tag
              .then(t => {
                if (!t) {
                  // eslint-disable-next-line functional/no-throw-statements
                  throw new Error(JSON.stringify(t));
                }
                this.insertPassback(t);
                return null;
              })
              .catch(e => {
                const msg = e instanceof Error ? e.message : 'unknown';
                // eslint-disable-next-line functional/no-throw-statements
                throw new Error(
                  'Passback promise fallthrough. Hiding experience: ' + msg,
                );
              });
          } else if (typeof tag === 'string' && tag) {
            this.insertPassback(tag);
          } else {
            // eslint-disable-next-line functional/no-throw-statements
            throw new Error('Passback fallthrough. Hiding experience');
          }
          return;
        } else if (
          (this.options.nestedIframe && !data.decisions) ||
          !data.decisions.inline ||
          data.decisions.inline.length < 1 ||
          data.decisions.inline[0] === null
        ) {
          resizeOwnIframe(
            0,
            !!this.options.nestedIframeShared,
            !!this.options.nestedIframeFullBleed,
          );
        }
        this.addExperience();
        if (data.decisions.inline && data.decisions.inline.length > 1) {
          this.appendPreview();
        }
      })
      .catch((err: Readonly<Error>) => {
        // Passback fallthrough isn't truly an error, so don't send an upstream message about it
        if (err.message.startsWith('Passback')) {
          console.warn('Flipp Experience failed to load: ', err.message);
        } else {
          Logger.error('Slot error', err);
          this.queueErrorBeacon(err);
        }

        if (this.options.nestedIframe && this.experiences.length === 0) {
          resizeOwnIframe(
            0,
            !!this.options.nestedIframeShared,
            !!this.options.nestedIframeFullBleed,
          );
        }
        this.callSlotFillResultCallback(false);
        return null;
      });
  }

  private getExperiences() {
    const placement: KevelPlacement = {
      divName: TARGET_NAME,
      networkId: NETWORK_ID,
      siteId: this.siteId,
      adTypes: AD_TYPES,
      count: this.experienceLimit,
    };

    if (
      this.zoneIds !== undefined &&
      this.zoneIds != null &&
      this.zoneIds.length > 0
    ) {
      placement['zoneIds'] = this.zoneIds;
    }

    const properties: {
      contentCode?: string | null;
      [key: string]: unknown;
    } = {};

    // Forward a preview code for targeting
    const url = new URL(window.location.toString());
    const contentCode =
      url.searchParams.get('flipp-content-code') ??
      this.options.flippContentCode;
    if (contentCode && contentCode.length > 0) {
      properties['contentCode'] = contentCode.slice(0, 32);
    }

    if (this.options.mediaPartnerDescriptiveValueTag) {
      properties['mediaPartnerDescriptiveValueTag'] =
        this.options.mediaPartnerDescriptiveValueTag.slice(0, 32);
    }

    placement['properties'] = properties;

    const keywords = [
      ...getContextKeywords(),
      ...checkPageForKeywords(badKeywords, true),
      ...getSDKKeywords(),
    ];

    const body: KevelRequest = {
      placements: [placement],
      url: this.getCanonicalUrl(),
      keywords,
    };

    const userKey = getUserKey(this.options);
    if (userKey) {
      body.user = { key: userKey };
    }

    this.getGeolocatedExperience(body);

    this.queueRequestContextBeacon(keywords);

    return null;
  }

  private getCanonicalUrl() {
    const canonical = document
      .querySelector("link[rel='canonical']")
      ?.getAttribute('href');
    if (canonical) {
      return canonical;
    }
    const url = new URL(window.location.href);
    return url.origin + url.pathname;
  }

  private queueRequestContextBeacon(keywords: readonly string[]) {
    const url = `${location.origin}${location.pathname}`;
    const urlParams = new URLSearchParams(window.location.search);
    const queryParams =
      urlParams.size === 0 ? [] : Array.from(urlParams.entries());

    AnalyticsQueue.enqueue(
      createDecisionRequestContextBeacon({
        partnerNameIdentifier: this.publisherNameIdentifier,
        siteId: this.siteId,
        zoneId: this.zoneIds[0] || 0,
        keywords: [...keywords],
        category: getContextCategory(),
        pageUrl: url,
        queryParams,
        options: this.options,
        postalCode: getPostalCode(),
        country: getCountry(),
      }),
    );
    Logger.debug('Analytics sendDecisionRequestContext');
    return null;
  }

  private queueRequestMadeBeacon() {
    AnalyticsQueue.enqueue(
      createDecisionRequestMadeBeacon({
        partnerNameIdentifier: this.publisherNameIdentifier,
        siteId: this.siteId,
        zoneId: this.zoneIds[0] || 0,
        options: this.options,
        postalCode: getPostalCode(),
        country: getCountry(),
      }),
    );
    Logger.debug('Analytics sendDecisionRequestMade');
    return null;
  }

  private queueFailureBeacon(response: Response) {
    AnalyticsQueue.enqueue(
      createDecisionResponseFailureBeacon({
        partnerNameIdentifier: this.publisherNameIdentifier,
        siteId: this.siteId,
        zoneId: this.zoneIds[0] || 0,
        errorMessage: 'Campaigns request failed',
        responseCode: response.status,
        options: this.options,
        postalCode: getPostalCode(),
        country: getCountry(),
      }),
    );
    Logger.debug('Analytics sendDecisionRequestFailure');
    return null;
  }

  private queueSuccessBeacon(data: Readonly<CampaignResponseBody>) {
    const getCustomData = (
      data: Readonly<CampaignResponseBody>,
    ): { url: string; campaignNameIdentifier: string } => {
      // eslint-disable-next-line functional/no-let
      let newCustomData = {
        url: 'no-campaign-url-nor-ulp',
        campaignNameIdentifier: 'no-campaign-name-identifier',
      };
      if (data.decisions?.inline && data.decisions.inline.length > 0) {
        const customData = data.decisions.inline[0].contents[0].data
          .customData as KevelCustomData;
        const customUrl = customData?.campaignConfigUrl ?? customData?.ulpUrl;
        newCustomData = {
          url: customUrl ?? 'no-campaign-url-nor-ulp',
          campaignNameIdentifier:
            customData?.campaignNameIdentifier ?? 'no-campaign-name-identifier',
        };
      }
      return newCustomData;
    };

    const hasContent =
      data.decisions.inline && data.decisions.inline[0].contents.length > 0;
    const { url, campaignNameIdentifier } = getCustomData(data);
    if (hasContent) {
      const kevelMetadata = data.decisions.inline[0].contents[0].data
        .customData as KevelCustomData;
      const pageUrl = new URL(window.location.toString());
      this.experienceLimit =
        Number(pageUrl.searchParams.get('flipp-experience-limit')) ||
        Number(kevelMetadata.experienceLimit) ||
        this.options.experienceLimit ||
        DEFAULT_EXPERIENCE_COUNT;
    }
    const hasMultipleExperiences =
      this.experienceLimit > 1 && data.decisions.inline?.length > 1;

    const decisions = data.decisions.inline
      ? [
          {
            campaignId: data.decisions.inline[0].campaignId,
            siteId: asNumber(this.siteId),
            zoneId: asNumber(this.zoneIds[0]) || 0,
            adId: data.decisions.inline[0].adId,
            creativeId: data.decisions.inline[0].creativeId,
            flightId: data.decisions.inline[0].flightId,
            advertiserId: data.decisions.inline[0].advertiserId,
            customUrl: hasContent ? { string: url } : null,
            positionIndex: hasMultipleExperiences ? { int: 1 } : null,
          },
        ]
      : [];

    const loc = data?.location;
    AnalyticsQueue.enqueue(
      createDecisionResponseSuccessBeacon({
        campaignNameIdentifier,
        partnerNameIdentifier: this.publisherNameIdentifier,
        siteId: this.siteId,
        zoneId: this.zoneIds[0] || 0,
        country: loc?.country,
        postalCode: loc?.postal_code,
        decisions,
        options: this.options,
      }),
    );
    Logger.debug('Analytics sendDecisionRequestSuccess');
    return null;
  }

  private queueErrorBeacon(err: Readonly<Error>) {
    AnalyticsQueue.enqueue(
      createDecisionResponseFailureBeacon({
        partnerNameIdentifier: this.publisherNameIdentifier,
        siteId: this.siteId,
        zoneId: this.zoneIds[0] || 0,
        errorMessage: err.message + ' - ' + (err.stack || ''),
        responseCode: 0,
        options: this.options,
        postalCode: getPostalCode(),
        country: getCountry(),
      }),
    );
    Logger.debug('Analytics sendDecisionRequestFailure');
    return null;
  }

  sendImpression = ({
    cpm,
    postalCode,
    campaignId,
  }: Readonly<{ cpm: number; postalCode: string; campaignId: number }>) => {
    AnalyticsQueue.enqueue(
      createSlotImpressionExperienceBeacon({
        partnerNameIdentifier: this.publisherNameIdentifier,
        siteId: this.siteId,
        zoneId: this.zoneIds[0] || 0,
        options: this.options,
        cpm,
        postalCode,
        campaignId,
      }),
    );
    Logger.debug('Analytics sendSlotimpressionExperience');
    if (this.options.campaigns) {
      const data = JSON.parse(
        this.options.campaigns,
      ) as Readonly<CampaignResponseBody>;
      const campaign = data.decisions.inline[0];
      Tags.addTagsFromCampaignResponse(
        campaign,
        {
          startCompact: !!this.options.startCompact,
        },
        data.location,
      );
      Tags.sendServedImpression();
    }
    return null;
  };
}

export default Slot;
