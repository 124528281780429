import { DeviceService } from '@flipp/shopper-ad-web-shared';

function setStyleForAdContainers(
  parentElement: Readonly<Document>,
  style: string,
) {
  setTimeout(() => {
    const adContainers = parentElement.querySelectorAll(
      '[data-freestar-ad], [data-google-query-id]',
    );
    adContainers.forEach(container => {
      if (
        container.children.length === 1 &&
        container
          .querySelector('iframe')
          ?.contentDocument?.querySelector('.flippxp-frame')
      ) {
        container.setAttribute('style', style);
      }
    });
  }, 500);
}

function setStyleForRootElements(
  parentElement: Readonly<Document>,
  style: string,
  rootElement: string,
) {
  const selectorArray = rootElement.split(',');
  selectorArray.forEach(selector => {
    const element = parentElement?.querySelector(selector) as HTMLElement;
    if (element && element.children.length === 1) {
      element.setAttribute('style', style);
    }
  });
}

function setStyleForIframeContainers(
  style: string,
  decisionExist: boolean,
  callSlotFillResultCallback: (arg0: boolean) => void,
  nestedIframeResizing: boolean,
  gamContainerWidth?: number,
) {
  const iframe = window.frameElement;
  if (!iframe) {
    return;
  }
  const parentElement = iframe.parentElement;
  const containerElement = parentElement?.parentElement;
  const parentChildrenLength = parentElement?.children.length;
  const containerChildrenLength = containerElement?.children.length;
  const platform = DeviceService.getDeviceInfo().type;
  if (parentElement && decisionExist) {
    parentElement.style.width = `100%`;
    if (gamContainerWidth && gamContainerWidth > 0 && platform !== 'mobile') {
      parentElement.style.width = `${gamContainerWidth}px`;
    }
  }

  if (
    !decisionExist &&
    containerElement &&
    parentChildrenLength === 1 &&
    containerChildrenLength === 1
  ) {
    containerElement.style.display = 'none';
    callSlotFillResultCallback(false);
    return;
  }

  if (
    nestedIframeResizing &&
    containerElement &&
    parentChildrenLength === 1 &&
    containerChildrenLength === 1
  ) {
    if (containerElement?.parentElement?.children?.length === 1) {
      containerElement.parentElement.setAttribute('style', style);
    }
    containerElement.setAttribute('style', style);
  }
}

export {
  setStyleForAdContainers,
  setStyleForRootElements,
  setStyleForIframeContainers,
};
